@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Montserrat', sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}

.ant-layout-sider{
  height: 100vh !important; 
  background :#fff !important;
}

.ant-layout-header span svg{
  height: 25px !important; 
  width: 25px !important;
  margin: 10px  !important
}

.ant-menu-overflow{
  /* justify-content: space-between !important; */
  justify-content: center;
}

.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}
#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}
.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.logo{
  text-align: center !important;
}
.ant-layout-content{
  margin: 0 !important;
  padding: 10px !important
}

.sweet-loading{
  position: fixed !important;
  top: 30% !important;
  height: 100% !important;
  width: 100% !important;
  z-index: 9999;

}

.flex{
  display: flex !important;
  align-items: center !important;
}
p{
  font-weight: 500 !important;
  margin: 2px !important;
  padding: 2px !important;
  font-size: 16px !important;
}
.bs{
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.site-div{
  border-radius: 15px !important;
}

button{
  background-color: #001529!important;
  color: #fff !important;
}

.login{
  padding-top: 150px !important;
}

input, textarea{
  border: 1px solid #001529 !important;
  box-shadow: none !important;
}

.ant-message{
  z-index: 9999!important;
}

.ant-btn{
  height: 32px !important;
}

.ant-input-search{
  width: inherit !important;
}

.ant-image-img{
  height: 40px !important;
  margin-bottom: -12px !important;
}

.searchIcon{
  margin-top: 17px !important;
}